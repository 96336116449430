import { graphql } from 'gatsby'
import React, { useState } from 'react'

import BannerSummerPromotion from '../components/Landing/BannerSummerPromotion'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import '../styles/landing.css'

const Page = ({ data }) => {
  return (
    <Layout dreamscapes={false} location="/">
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.gatsbyImageData
            ?.srcWebp
        }
      />
      <BannerSummerPromotion />
    </Layout>
  )
}

export default Page

export const query = graphql`
  {
    Trial: file(relativePath: { eq: "landing_new/customer.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    SEO: cockpitPage(cannonical_url: { value: { eq: "/" } }) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }
      TEST_banner_image {
        value {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
    }
  }
`
